// @import url('https://fonts.googleapis.com/css2?family=Outfit:wght@400;700&family=PT+Sans:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800&family=PT+Sans:wght@400;700&display=swap');

body {
  font-family: 'Outfit', sans-serif;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  font-family: 'PT Sans', sans-serif !important;
}

.custom-light-border-end {
  border-right: 1px solid rgba(43, 44, 45, 0.25);
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.14);
}

.custom-light-border-bottom {
  border-bottom: 1px solid rgba(43, 44, 45, 0.25);
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.14);
}

$fontWeights: (
  100,
  200,
  300,
  400,
  500,
  600,
  700,
  800
);

@each $weight in $fontWeights {
  .fw-#{$weight} {
    font-weight: $weight !important
  }
}

.custom-light-border-end {
  border-right: 1px solid rgba(43, 44, 45, 0.25);
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.14);
}

.custom-light-border-bottom {
  border-bottom: 1px solid rgba(43, 44, 45, 0.25);
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.14);
}

#custom-switch {
  height: 1.5rem;
  width: 3rem;
}

#billing-switch {
  height: 1.5rem;
  width: 3rem;
  background-color: #D91379;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='white'/%3e%3c/svg%3e")
}

.zoom {
  transition: transform .2s;
}

.zoom:hover {
  transform: scale(1.04);
}

.btn-highlight:hover {
  background-color: #FFBFE0 !important;
}

.btn-white:hover {
  background-color: #FFF2F9 !important;
}

.btn-text:hover {
  color: #96006A !important;
}

.title-shadow:hover {
  text-shadow: 0px 5px 5px #DAD8D8;
}

.no-change-on-hover {
  cursor: default;
  opacity: 65%;
}

.no-change-on-hover:hover {
  background-color: #D91379;
  cursor: default;
}

.vertical-scroll {
  overflow-y: auto;
  padding: 1.25rem;
  margin: -1.25rem;
}

.hide-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

// @each $name,
// $color in $theme-colors {
//   .control-#{$name} {
//     @extend .text-dark;
//     @extend .border-#{$name} !optional;
//     @extend .shadow-none;
//     background-color: scale-color($color, $lightness: 50%) !important;
//     transition: all .2 ease-in-out;
//     box-shadow: none !important;
//     border-radius: 5px 5px 0px 0px !important;

//     &:focus-visible,
//     &:hover {
//       background-color: lighten($color, 10%) !important;
//       border-bottom: 1px solid scale-color($color, $saturation: 70%);
//       transition: all .2 ease-in-out;
//     }
//   }
// }

// $tabelColums: (
//   1,
//   2,
//   3,
//   4,
//   5,
//   6,
//   7,
//   8,
//   9,
//   10,
//   11,
//   12
// );

// @each $amount in $tabelColums {
//   .table-td-#{$amount} {
//     width: calc(100%/$amount);
//   }
// }

// .custom-datepicker {
//   .react-datepicker__input-container {
//     width: auto;
//     display: flex;
//   }
//   .react-datepicker__close-icon {
//     position: static;
//   }
//   .react-datepicker__close-icon::after {
//     background: transparent;
//     @extend .link-dark;
//     font-size: 20px;
//   }
// }